import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import "./App.css";
import "./assets/fonts/feather/css/feather.css";
import { Suspense } from "react";
import AuthGuard from "./guard/AuthGurad";
import { AuthService } from "./services/AuthService";
import AuthMainGuard from "./guard/AuthMainGuard";
import Topic from "./components/Topic/Topic";
import CoachName from "./components/CoachChatBot/coachName";
import Exam from "./components/Exam/Exam";
import UserDetails from "./components/UserDetails/UserDetails";
import Prompts from "./components/Prompts/prompts";

const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const User = React.lazy(() => import("./components/User/User"));
const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <AuthGuard>
        <Login />
      </AuthGuard>
    ),
  },
  { path: "/register", element: <Register /> },
  {
    path: "/",
    element: (
      <AuthMainGuard>
        <Dashboard />
      </AuthMainGuard>
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <AuthMainGuard>
            <User />
          </AuthMainGuard>
        ),
      },
      {
        path: "/user",
        element: (
          <AuthMainGuard>
            <User />
          </AuthMainGuard>
        ),
      },
      {
        path: "/topic",
        element: (
          <AuthMainGuard>
            <Topic />
          </AuthMainGuard>
        ),
      },
      {
        path: "/coaches",
        element: (
          <AuthMainGuard>
            <CoachName />
          </AuthMainGuard>
        ),
      },
      {
        path: "/exam",
        element: (
          <AuthMainGuard>
            <Exam />
          </AuthMainGuard>
        ),
      },
      {
        path: "/user/:userId",
        element: (
          <AuthMainGuard>
            <UserDetails  />
          </AuthMainGuard>
        ),
      },
      {
        path: "/prompts",
        element: (
          <AuthMainGuard>
            <Prompts />
          </AuthMainGuard>
        ),
      }
    ],
  },
]);

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [authLoader, setAuthLoader] = useState(false);
  useEffect(() => {
    async function authData() {
      try {
        let response: any = await AuthService.AutoLogin();
        if (response.data.user._id && response.data.user.is_admin) {
          AuthService.userLoggerIn = true;
          setUserLoggedIn(true);
        }
        setAuthLoader(true);
      } catch (eror) {
        setAuthLoader(true);
      }
    }
    authData();
  }, []);
  if (!authLoader) {
    return <div>... Loading</div>;
  } else {
    return (
      <div className="App">
        <Suspense fallback={<>loading ...</>}>
          <RouterProvider router={router} />
        </Suspense>
      </div>
    );
  }
}

export default App;
