import React, { useEffect, useState } from 'react'
import "./prompts.css";
import preloader from "./../../assets/images/CC-Loaderfinal.gif";
import {
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { promptsService } from '../../services/promptsService';


type JsonData = {
  _id: { $oid: string };
  useGPT4Prompt: boolean;
  prompt4: string;
  deviatePrompt: Array<{ role: string; content: string }>;
  prompt: string;
  geminiPrompt:string;
  systemRoleList: {
    [key: string]: string;
  };
  coachPrompt: string;
  coachWelcomeMessage: string;
};

export default function Prompts({ initialData = {} }: { initialData?: Partial<JsonData> }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLive, setIsLive] = useState(true);
  const [userLoader, setUserLoader] = useState(false);

  const [promptData, setPromptData] = useState<JsonData>({
    _id: { $oid: '' },
    useGPT4Prompt: false,
    prompt4: '',
    deviatePrompt: [],
    prompt: '',
    geminiPrompt:'',
    systemRoleList: {},
    coachPrompt: '',
    coachWelcomeMessage: '',
    ...initialData,
  });

  const [updatedPromptData, setUpdatedPromptData] = useState<JsonData>({
    _id: { $oid: '' },
    useGPT4Prompt: false,
    prompt4: '',
    deviatePrompt: [],
    prompt: '',
    geminiPrompt:'',
    systemRoleList: {},
    coachPrompt: '',
    coachWelcomeMessage: '',
    ...initialData,
  });

  const [versionsList, setVersionsList]: any = useState([]);
  const [selectedVersion, setSelectedVersion]: any = useState([]);


  useEffect(() => {
    getPrompts();
    getPromptVersions();
  }, []);

  async function getPrompts() {
    try {
      setUserLoader(true);
      let response: any = await promptsService.getPrompts();
      setPromptData(response.data.promptData);
      setUpdatedPromptData(response.data.promptData);

      setUserLoader(false);
    } catch (error) {
      setUserLoader(false);
    }
  }

  async function getPromptVersions() {
    try {
      setUserLoader(true);
      let response: any = await promptsService.getPromptVersions();
      setVersionsList(response.data.versionsList);
      setUserLoader(false);
    } catch (error) {
      setUserLoader(false);
    }
  }

  async function getPromptById(id: string, isLive: boolean) {
    try {
      setUserLoader(true);
      let response: any = await promptsService.getPromptById(id, isLive);
      setPromptData(response.data.promptData);
      setUpdatedPromptData(response.data.promptData);
      setUserLoader(false);
    } catch (error) {
      setUserLoader(false);
    }
  }


  async function updatePrompt() {
    try {
      setUserLoader(true);

      await promptsService.updatePrompt(updatedPromptData);
      getPromptVersions();
      setPromptData(updatedPromptData);

      setUserLoader(false);
    } catch (error) {
      setUserLoader(false);
    }
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    updatePrompt();
  };

  const handleCancel = () => {
    setIsEditing(false);
    setUpdatedPromptData(promptData);
  };

  const handleChange = (key: keyof JsonData, value: any) => {
    setUpdatedPromptData((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleVersionChange = async (versionId: any) => {
    let selectedVersion = versionsList.find((version: any) => version.id == versionId);
    setSelectedVersion(selectedVersion);
    setIsLive(selectedVersion.isLive);
    getPromptById(selectedVersion.id, selectedVersion.isLive);
  }

  const publishVersion = async (selectedVersionId: string) => {
    try {
      setUserLoader(true);
      let liveVersionId = versionsList.find((v: any) => v.isLive).id;
      let response = await promptsService.publishVersion(selectedVersionId, liveVersionId);

      setPromptData(response.data.promptData);
      setUpdatedPromptData(response.data.promptData);
      setIsLive(true);

      await getPromptVersions();

      let selectedVersion = versionsList.find((v: any) => v.version == 'Live');
      setSelectedVersion(selectedVersion);

      setUserLoader(false);
    } catch (error) {
      setUserLoader(false);
    }
  }


  const handlePublishVersion = () => {
    publishVersion(selectedVersion.id)
  }


  const renderField = (label: string, value: string | boolean, editComponent: React.ReactNode) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1 topicHeading">{label}</label> <br />
      {isEditing ? (
        editComponent
      ) : (
        <div className="bg-gray-100 p-2 rounded">{typeof value === 'boolean' ? value.toString() : value}</div>
      )}
    </div>
  );

  return (
    <section className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="page-header-title">
            <h5 className="m-b-10" style={{ fontSize: '30px' }}></h5>
          </div>
          <div className="pcoded-inner-content">
            {userLoader && (
              <div className="preloader">
                <img src={preloader} alt="preloder" />
              </div>
            )}

            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    <Card.Title as="h5">Prompts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div >
                      <div className="header">
                        {!isEditing ? (
                          <div className="edit-controls">
                            <strong className='dropdown-label'>Version: </strong>
                            <select className="dropdown" onChange={(e) => handleVersionChange(e.target.value)} value={selectedVersion.id}>

                              {versionsList.map((version: any) => (
                                <option key={version.version} value={version.id}>
                                  {version.version}
                                </option>
                              ))}
                            </select>

                            {!isEditing && isLive && (
                              <button onClick={handleEdit} className="btn-outline">
                                Edit
                              </button>
                            )}
                            {!isEditing && !isLive && (
                              <button onClick={handlePublishVersion} className="btn-outline">
                                Publish
                              </button>
                            )}
                          </div>
                        ) : (
                          <div >
                            {isLive && (
                              <div className="footer">
                                <button onClick={handleCancel} className="btn-cancel">
                                  Cancel
                                </button>
                                <button onClick={handleSave} className="btn-outline">
                                  Save
                                </button>
                              </div>
                            )}
                          </div>
                        )
                        }
                      </div>

                      <div className="content">
                        {renderField('Prompt 4', updatedPromptData.prompt4, (
                          <textarea
                            value={updatedPromptData.prompt4}
                            onChange={(e) => handleChange('prompt4', e.target.value)}
                            rows={5}
                            className="w-full border border-gray-300 rounded-md px-3 py-2"
                          />
                        ))}

                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700 mb-1 topicHeading">Deviate Prompt</label> <br />
                          {isEditing ? (
                            updatedPromptData.deviatePrompt.map((item, index) => (
                              <div key={index} className="mt-2">


                                <strong className='topicSubHeading text-capitalize'>{index + 1}. {item.role} : <br /></strong>


                                <textarea
                                  value={item.content}
                                  onChange={(e) => {
                                    const newDeviatePrompt = [...updatedPromptData.deviatePrompt];
                                    newDeviatePrompt[index].content = e.target.value;
                                    handleChange('deviatePrompt', newDeviatePrompt);
                                  }}
                                  rows={3}
                                  placeholder="Content"
                                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                                />
                              </div>
                            ))
                          ) : (
                            <div className="bg-gray-100 p-2 rounded">
                              {updatedPromptData.deviatePrompt.map((item, index) => (
                                <div key={index} className="mb-2">
                                  <strong className='topicSubHeading text-capitalize' >{index + 1}. {item.role}:</strong> <br />{item.content}<br />

                                </div>
                              ))}
                            </div>

                          )}
                        </div>

                        {renderField('Prompt', updatedPromptData.prompt, (
                          <textarea
                            value={updatedPromptData.prompt}
                            onChange={(e) => handleChange('prompt', e.target.value)}
                            rows={5}
                            className="w-full border border-gray-300 rounded-md px-3 py-2"
                          />
                        ))}

                          {renderField('Gemini Prompt', updatedPromptData.geminiPrompt, (
                          <textarea
                            value={updatedPromptData.geminiPrompt}
                            onChange={(e) => handleChange('geminiPrompt', e.target.value)}
                            rows={5}
                            className="w-full border border-gray-300 rounded-md px-3 py-2"
                          />
                        ))}

                        <div className="mb-4">
                          <label className="block text-sm font-medium text-gray-700 mb-1 topicHeading">System Role List</label><br />
                          {isEditing ? (
                            Object.entries(updatedPromptData.systemRoleList).map(([key, value], index) => (
                              <div key={key} className="mt-2">


                                <strong className='topicSubHeading text-capitalize'>{index + 1}. {key} : <br /></strong>

                                <textarea
                                  value={value}
                                  onChange={(e) => {
                                    const newSystemRoleList = { ...updatedPromptData.systemRoleList };
                                    newSystemRoleList[key] = e.target.value;
                                    handleChange('systemRoleList', newSystemRoleList);
                                  }}
                                  rows={3}
                                  placeholder="Description"
                                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                                />
                              </div>
                            ))
                          ) : (
                            <div className="bg-gray-100 p-2 rounded">
                              {Object.entries(updatedPromptData.systemRoleList).map(([key, value], index) => (
                                <div key={key} className="mb-2">
                                  <strong className='topicSubHeading text-capitalize' >{index + 1}. {key} : <br /></strong> {value}
                                  <br />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </Col>
            </Row>




          </div>
        </div>
      </div>
    </section>
  );
}
