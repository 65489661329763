import { axiosPost, axiosGet } from "./HttpBaseService";

export const promptsService = {

  getPrompts: () => {
    return axiosGet(`${process.env.REACT_APP_API}/admin/getPrompts`);
  },
  getPromptById: (id:string,isLive:boolean|number) => {
    isLive=isLive?1:0;
    return axiosGet(`${process.env.REACT_APP_API}/admin/getPromptById/${id}/${isLive}`);
  },

  updatePrompt: (updatedPromptData:any) => {
    return axiosPost(`${process.env.REACT_APP_API}/admin/updatePrompt`,{updatedPromptData});
  },
  getPromptVersions: () => {
    return axiosGet(`${process.env.REACT_APP_API}/admin/getPromptVersions`);
  },
  publishVersion: (newVersionId:string,liveVersionId:string) => {
    return axiosPost(`${process.env.REACT_APP_API}/admin/publishPromptVersion`,{newVersionId,liveVersionId});
  },

};
